@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Roboto:wght@300;400;500;700&display=swap');

body {
    margin: 0;
    /*font-family: 'Lato', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a, a:visited {
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.big {
    font-family: 'Fjalla One' !important;
}

.slim {
    font-weight: 300!important;
}

.fullHeightCard {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.fullHeightCard .MuiCardContent-root {
    height: 100%;
}

.fullHeightCard .MuiCardActions-root {
    margin-top: auto;
}

::-webkit-scrollbar {
    width: 3px
}

::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    background: #e6e6e6;
}

.MuiCardContent-root::-webkit-scrollbar {
    width: 2px
}

.MuiCardContent-root::-webkit-scrollbar-thumb {
    background: #a6a6a6 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.MuiBox-root::-webkit-scrollbar {
    width: 2px
}

.MuiBox-root::-webkit-scrollbar-thumb {
    background: #a6a6a6 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: transparent;
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-button {
    background: transparent;
}

::-webkit-scrollbar-track-piece {
    background: #fafafa;
}

::-webkit-scrollbar-thumb {
    background: transparent;
}

/* ----- */
.slidedown-body-message {
    color: violet !important;
    font-size: 15px !important;
}

.slidedown-button.primary:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: violet !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.slidedown-button.primary {
    background-color: #8d21e9 !important;
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 8px !important;
    border-radius: 4px !important;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    /*color: #8d21e9!important;*/
}

.slidedown-button.secondary:hover {
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    background-color: rgba(141, 33, 233, 0.04) !important;
}

.slidedown-button.secondary {
    background: none !important;
    background-color: transparent;
    margin-right: 16px !important;
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-align-items: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    -webkit-justify-content: center !important;
    justify-content: center !important;
    position: relative !important;
    box-sizing: border-box !important;
    -webkit-tap-highlight-color: transparent !important;
    outline: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    vertical-align: middle !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase !important;
    min-width: 64px !important;
    padding: 6px 8px !important;
    border-radius: 4px !important;
    color: #8d21e9 !important;
}

.onesignal-slidedown-dialog {
    margin-top: 8px !important;
    border-radius: 4px !important;
}

.onesignal-bell-launcher .onesignal-bell-launcher-message {
    background-color: #8d21e9 !important;
    background: #8d21e9 !important;
}

.onesignal-bell-launcher.onesignal-bell-launcher-bottom-right .onesignal-bell-launcher-message:after {
    border-left-color: #8d21e9 !important;
}
